import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import Tooltip from '@reach/tooltip'
import '@reach/tooltip/styles.css'
import { Lottie } from '@crello/react-lottie'
import * as animationLoading from '../../animations/loading.json'
import MapGL, {
	FlyToInterpolator,
	Marker,
	WebMercatorViewport,
} from 'react-map-gl'
import { easeCubic } from 'd3-ease'
import { point, featureCollection } from '@turf/helpers'
import bbox from '@turf/bbox'
import { MapPin, Star } from 'react-feather'

import { AddressPin, DealerPin } from './styles'
import 'mapbox-gl/dist/mapbox-gl.css'

/**
 * MapBox - Map with pins for address input and closest dealer
 * @param {object} props
 * @param {[]} props.dealers dealer features
 * @param {object} props.selectAddress selected address
 * @param {number} props.selectDealer dealer index
 * @param {(i: number) => void} props.handleSelectDealer change dealer by array index
 * @returns {JSX.Element} MapBox
 */
const MapBox = ({
	loading,
	dealers,
	selectAddress,
	selectType,
	selectDealer,
	handleSelectDealer,
	locale,
	full,
	...rest
}) => {
	const coordinates = {
		'en-AU': {
			longitude: 134.361,
			latitude: -25.609,
		},
		'en-NZ': {
			longitude: 172.1600924,
			latitude: -40.7430206,
		},
	}
	const [viewport, setViewport] = useState({
		width: 384,
		height: 340,
		longitude: coordinates[locale === undefined ? 'en-AU' : locale].longitude,
		latitude: coordinates[locale === undefined ? 'en-AU' : locale].latitude,
		zoom: 3,
		bearing: 0,
		pitch: 0,
	})
	useEffect(() => {
		const defaultPoint = point(
			[
				coordinates[locale === undefined ? 'en-AU' : locale].longitude,
				coordinates[locale === undefined ? 'en-AU' : locale].latitude,
			],
			{
				name: 'Center of Australia',
			}
		)
		if (
			Array.isArray(selectAddress.coordinates) &&
			selectAddress.coordinates.length >= 2
		) {
			const addressPoint = point(selectAddress.coordinates, {
				name: 'You are here',
			})
			if (
				Array.isArray(selectAddress.coordinates) &&
				selectAddress.coordinates.length >= 2 &&
				Array.isArray(dealers) &&
				dealers.length >= 1 &&
				dealers[0].name
			) {
				const dealerPoints = dealers.map(dealer =>
					point(dealer.location.coordinates, { name: dealer.name })
				)
				const features = featureCollection([...dealerPoints, addressPoint])
				const [minLng, minLat, maxLng, maxLat] = bbox(features)
				let mapViewport = new WebMercatorViewport(viewport)
				const { longitude, latitude, zoom } = mapViewport.fitBounds(
					[
						[minLng, minLat],
						[maxLng, maxLat],
					],
					{
						padding: { top: 80, bottom: 40, left: 40, right: 40 },
						offset: [0, 0],
					}
				)
				return setViewport(viewport => ({
					...viewport,
					longitude,
					latitude,
					zoom,
					transitionDuration: 5000,
					transitionInterpolator: new FlyToInterpolator(),
					transitionEasing: easeCubic,
				}))
			}
			const addressFeature = featureCollection([addressPoint])
			const [minLng, minLat, maxLng, maxLat] = bbox(addressFeature)
			let mapViewport = new WebMercatorViewport(viewport)
			const { longitude, latitude } = mapViewport.fitBounds(
				[
					[minLng, minLat],
					[maxLng, maxLat],
				],
				{
					padding: { top: 80, bottom: 40, left: 40, right: 40 },
					offset: [0, 0],
				}
			)
			return setViewport(viewport => ({
				...viewport,
				longitude,
				latitude,
				zoom: 8,
				transitionDuration: 5000,
				transitionInterpolator: new FlyToInterpolator(),
				transitionEasing: easeCubic,
			}))
		}
		const feature = featureCollection([defaultPoint])
		const [minLng, minLat, maxLng, maxLat] = bbox(feature)
		let mapViewport = new WebMercatorViewport(viewport)
		const { longitude, latitude } = mapViewport.fitBounds(
			[
				[minLng, minLat],
				[maxLng, maxLat],
			],
			{
				padding: { top: 80, bottom: 40, left: 40, right: 40 },
				offset: [0, 0],
			}
		)
		return setViewport(viewport => ({
			...viewport,
			longitude,
			latitude,
			zoom: 3,
			transitionDuration: 5000,
			transitionInterpolator: new FlyToInterpolator(),
			transitionEasing: easeCubic,
		})) // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectAddress.coordinates, dealers])

	return (
		<div
			className="mapbox-gl"
			tw="flex items-center justify-center mb-4"
			css={[
				css`
					.mapboxgl-ctrl-attrib {
						display: none;
					}
				`,
			]}
			{...rest}
		>
			<div
				tw="relative w-full mx-auto overflow-hidden  bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 "
				css={[
					{ minHeight: '340px' },
					loading && tw`opacity-50 cursor-not-allowed`,
					!full && tw`max-w-sm rounded-md border border-gray-300`,
				]}
			>
				{loading && (
					<Lottie
						width="60px"
						height="60px"
						className="lottie-container loading"
						css={tw`z-30 absolute inset-1/2 transform -translate-x-1/2 -translate-y-1/2 w-0 h-0 text-blue-500 fill-current`}
						config={{
							animationData: animationLoading.default,
							loop: true,
							autoplay: true,
							renderer: 'svg',
						}}
						aria-hidden
					/>
				)}
				<MapGL
					{...viewport}
					width="100%"
					height="340px"
					mapStyle="mapbox://styles/jeremyswann/ck6ty567r02b21jmgvvpcetnt"
					onViewportChange={viewport => setViewport(viewport)}
					dragToRotate={false}
					mapboxApiAccessToken={process.env.GATSBY_MAPBOX_TOKEN}
				>
					{Array.isArray(selectAddress.coordinates) &&
						selectAddress.coordinates.length === 2 && (
							<Marker
								longitude={selectAddress.coordinates[0]}
								latitude={selectAddress.coordinates[1]}
								offsetTop={-20}
								offsetLeft={-10}
							>
								<Tooltip label="Your location">
									<AddressPin>
										<Star size={20} tw="fill-current text-yellow-500" />
									</AddressPin>
								</Tooltip>
							</Marker>
						)}
					{Array.isArray(dealers) &&
						dealers.length >= 1 &&
						dealers.map((dealer, i) => (
							<Marker
								key={dealer._id || 'dealerKey0' + i}
								longitude={dealer.location.coordinates[0]}
								latitude={dealer.location.coordinates[1]}
								offsetTop={-20}
								offsetLeft={-10}
							>
								<Tooltip
									label={`${dealer.name} ${parseFloat(
										dealer.meta.distance / 1000
									).toFixed(2)} kms`}
								>
									<DealerPin
										type="button"
										onClick={() => {
											handleSelectDealer(i)
										}}
									>
										<MapPin
											size={20}
											css={
												i === selectDealer
													? tw`fill-current text-blue-500`
													: tw`fill-current text-white`
											}
										/>
									</DealerPin>
								</Tooltip>
							</Marker>
						))}
				</MapGL>
			</div>
		</div>
	)
}
MapBox.propTypes = {
	loading: PropTypes.bool,
	selectAddress: PropTypes.shape({
		name: PropTypes.string,
		coordinates: PropTypes.arrayOf(PropTypes.number),
	}),
	selectType: PropTypes.shape({
		name: PropTypes.string,
		type: PropTypes.string,
	}),
	dealers: PropTypes.arrayOf(
		PropTypes.shape({
			location: PropTypes.shape({
				type: PropTypes.string,
				coordinates: PropTypes.arrayOf(PropTypes.number),
			}),
			name: PropTypes.string,
			meta: PropTypes.shape({ distance: PropTypes.number }),
			type: PropTypes.arrayOf(PropTypes.string),
		})
	),
	selectDealer: PropTypes.number,
	handleSelectDealer: PropTypes.func,
}
export default MapBox
