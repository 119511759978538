import tw, { styled } from 'twin.macro'
export const ContentsSection = tw.section`w-full bg-gray-200`
export const ContentsContainer = tw.div`w-95 md:w-11/12 bg-white py-10 px-4 md:px-10 mx-auto border-l border-r border-gray-300 border-b rounded-b-lg mb-16`

export const TextsImageContainer = tw.div`flex flex-col md:flex-row mb-10`
export const Header = tw.h2`font-display text-3xl md:text-4xl font-bold mb-6`
export const HeaderSubText = styled.p`
	${tw`font-display text-base md:text-lg mb-4`}

	span {
		${tw`underline text-blue-500`}
	}

	> a {
		${tw`underline text-blue-500 hover:no-underline font-display`}
	}
`
export const AddressPin = styled.div`
	${tw`p-1 border-0 rounded-full`}
	svg {
		filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25));
		stroke: #22292f;
		path {
			stroke: #22292f;
		}
	}
`
export const DealerPin = styled.button`
	${tw`p-1 border-0 rounded-full`}
	svg {
		filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.25));
		stroke: #22292f;
		path {
			stroke: #22292f;
		}
	}
`
export const TextsContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 767px) {
		align-items: center;
	}
`;

export const DealersContainer = tw.div`mt-12`
export const LogoContainer = styled.div`
	${tw`flex flex-col md:flex-row items-center justify-center mt-4`}

	> img {
		${tw`w-auto md:w-6/12 px-4`}
		max-width: 250px;
	}
`
export const DealerLogoContainer = styled.div`
	${tw`flex flex-col md:flex-row items-center justify-center mt-12 w-full mx-auto flex-wrap`}
	a {
		${tw`w-full md:w-1/3 lg:w-1/4 px-3 mb-4`}
		max-width: 300px;
	}
`
