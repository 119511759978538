import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Transition } from '@headlessui/react'
import tw, { css } from 'twin.macro'
/**
 * AddressInput - address query listbox
 * @param {object} props
 * @param {bool} props.hideLabel
 * @param {string} props.queryAddress query address
 * @param {() => void} props.onQueryAddress update address query
 * @param {array} props.addresses array of address results
 * @param {string} props.selectAddress selected address
 * @param {() => void} props.onSelectAddress update address selection
 * @returns {JSX.Element} AddressInput
 */
const AddressInput = ({
	placeholder = 'street address',
	required = false,
	hideLabel = false,
	queryAddress,
	onQueryAddress,
	addresses,
	selectAddress,
	onSelectAddress,
	...props
}) => {
	const [focus, onFocus] = useState(false)
	const handleQueryAddress = event => onQueryAddress(event.target.value)
	const handleAddressSelect = address => {
		onSelectAddress(address)
		return onFocus(false)
	}
	return (
		<div tw="flex items-center justify-center mb-4 w-full" {...props}>
			<div tw="w-full max-w-sm mx-auto">
				<div id="listbox" tw="space-y-1">
					<label
						htmlFor="address-input"
						id="address-search-label"
						tw="block text-sm leading-5 font-medium text-gray-700"
						css={hideLabel && tw`hidden`}
					>
						Search by {placeholder}
					</label>
					<div tw="relative">
						<span tw="inline-block w-full rounded-md shadow-sm">
							<div tw="cursor-default relative w-full text-left">
								<input
									id="address-input"
									type="text"
									autoComplete="street-address"
									aria-labelledby="address-search-label"
									onChange={handleQueryAddress}
									onFocus={event => onFocus(true)}
									onBlur={event => onFocus(false)}
									placeholder={
										!queryAddress && !(selectAddress && selectAddress.name)
											? 'Enter your ' + placeholder
											: 'Please select a ' + placeholder
									}
									value={
										focus ? queryAddress : selectAddress && selectAddress.name
									}
									required={required}
									tw="block truncate py-2 pl-3 pr-10 rounded-md border border-gray-300 bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-full!"
								/>
								<span tw="absolute inset-y-0 right-0 flex items-center pr-2 py-2 pointer-events-none">
									<svg
										tw="h-5 w-5 text-gray-400"
										viewBox="0 0 20 20"
										fill="none"
										stroke="currentColor"
									>
										<path
											d="M7 7l3-3 3 3m0 6l-3 3-3-3"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</span>
							</div>
						</span>

						<Transition
							show={Boolean(focus && queryAddress)}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
							tw="z-10 absolute mt-1 sm:w-max w-full rounded-md bg-white shadow-lg"
							onFocus={event => onFocus(true)}
							onBlur={event => onFocus(false)}
						>
							<ul
								aria-labelledby="address-search-label"
								id="address-listbox"
								role="listbox"
								tabIndex="0"
								tw="max-h-60 rounded-md py-1 text-base leading-6 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm sm:leading-5"
							>
								{Array.isArray(addresses) && addresses.length >= 1 ? (
									addresses.map(address => (
										<li
											role="option"
											tabIndex="-1"
											aria-selected={
												address.name === (selectAddress && selectAddress.name)
											}
											key={address.name}
											onClick={() => handleAddressSelect(address)}
											onKeyUp={() => handleAddressSelect(address)}
										>
											<div
												css={[
													tw`cursor-default select-none relative py-2 pl-8 pr-4 text-gray-900`,
													tw`transition ease-in-out duration-150 hover:text-white hover:bg-blue-500`,
												]}
											>
												<span
													css={[
														tw`font-normal block truncate`,
														address.name ===
															(selectAddress && selectAddress.name) &&
															tw`font-semibold`,
													]}
												>
													{address.name}
												</span>
												{address.name ==
													(selectAddress && selectAddress.name) && (
													<span tw="absolute inset-y-0 left-0 flex items-center pl-1.5">
														<svg
															tw="h-5 w-5"
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path
																fillRule="evenodd"
																d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
																clipRule="evenodd"
															/>
														</svg>
													</span>
												)}
											</div>
										</li>
									))
								) : (
									<li role="option" tabIndex="-1" aria-selected="false">
										<div tw="cursor-default select-none relative py-2 pl-8 pr-4 text-gray-900">
											<span tw="font-normal block truncate">No results</span>
										</div>
									</li>
								)}
							</ul>
						</Transition>
					</div>
				</div>
			</div>
		</div>
	)
}
AddressInput.propTypes = {
	hideLabel: PropTypes.bool,
	queryAddress: PropTypes.string,
	onQueryAddress: PropTypes.func,
	addresses: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			coordinates: PropTypes.array,
		})
	),
	selectAddress: PropTypes.shape({
		name: PropTypes.string,
		coordinates: PropTypes.array,
	}),
	onSelectAddress: PropTypes.func,
}
export default AddressInput
